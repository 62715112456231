.Toastify {
  $that: &;
  // --toastify-toast-width: 420px;

  &__toast {
    font: inherit;
    font-size: 14px;
    line-height: 1.2;

    &:hover,
    &:focus-within {
      #{$that}__close-button svg {
        opacity: 1;
      }
    }
  }

  &__toast-theme--colored,
  &__toast-theme--dark {
    #{$that}__btn {
      --btn-bg: var(--white);

      &:hover:not(:disabled),
      &.focus-visible:not(:disabled) {
        --btn-border: var(--b-bg);
        background-color: transparent;
      }
    }
  }

  &__toast-icon {
    width: 24px;
  }

  &__btn-wrap {
    margin-top: 6px;
  }

  &__btn {
    padding: 6px 12px;
    --btn-bg: var(--slate-2);
    font-size: 12px;
    flex-shrink: 0;
  }

  &__close-button svg {
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  }
}
