.c-modal {
  padding: 0;
  max-width: var(--m-max-width);
  display: inline-block;
  position: relative;
  vertical-align: middle;
  background-color: var(--m-bg);
  text-align: left;
  font-size: 12px;
  border-radius: 12px;
  box-shadow: 0 0 6px rgba(183, 183, 183, 0.32);
  --m-max-width: var(--modal-max-width, 450px);
  --m-bg: var(--modal-bg, #fff);
  --m-accent: var(--modal-accent, #00719c);
  --m-head: var(--modal-head, var(--m-accent));
  --m-btn: var(--modal-btn, currentColor);
  --m-btn-focus: var(--modal-btn-focus, var(--m-btn));
  $that: &;

  &.modal-role-confirmClosing {
    --modal-max-width: 400px;
  }

  &-overlay {
    padding: 16px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 80%);
    z-index: 100;
    text-align: center;
    overflow: auto;

    &::before {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

    &.ReactModal__Overlay {
      opacity: 0;
      transition: opacity 150ms ease-in-out;
    }

    &.ReactModal__Overlay--after-open {
      opacity: 1;
    }

    &.ReactModal__Overlay--before-close {
      opacity: 0;
    }
  }

  &--wide {
    max-width: 650px;
  }

  &--expand {
    width: 100%;
  }

  &--fb {
    width: 100%;
    max-width: 540px;
  }

  &--info {
    max-width: 300px;
  }

  &__head {
    padding: 14px 32px;
    text-align: center;
    font-weight: 400;
    line-height: 1;
    font-size: inherit;
    margin: 0;
    border-bottom: 1px solid #ced4da;
    color: var(--m-head);
  }

  &__body {
    padding: 24px;
    display: flex;
    flex-direction: column;
    border-radius: inherit;
    gap: 8px;
  }

  &__icon {
    width: 64px;
    height: 64px;
    margin: 0 auto;
    position: relative;
    margin-bottom: 8px;

    svg {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      fill: var(--icon-fill);
    }

    &--success {
      --icon-fill: #3d9d57;
    }

    &--warning {
      --icon-fill: #eb6657;
    }

    &--error {
      --icon-fill: #e65361;
    }
  }

  &__detail-wrap {
    display: flex;
    flex-direction: column;
    gap: 4px;

    & > *:empty {
      display: none;
    }
  }

  &__detail-text {
    font-size: 16px;
    text-align: center;
  }

  &__btn-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
  }

  &__btn {
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    line-height: 1;
    position: relative;
    border-radius: 4px;
    outline-color: var(--btn-outline, var(--m-accent));
    transition: opacity 0.15s ease-in-out, filter 0.15s ease-in-out, color 0.15s ease-in-out,
      background-color 0.15s ease-in-out;
    font-size: 16px;
    font-weight: 400;

    &:active {
      opacity: 0.7;
    }

    &--close-top {
      width: 26px;
      height: 26px;
      position: absolute;
      top: 8px;
      right: 8px;
      border-radius: 8px;

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: inherit;
        border: 1px solid var(--m-btn-focus);
        opacity: 0;
        transition: opacity 0.15s ease-in-out;
      }

      svg {
        position: absolute;
        inset: 0;
        width: 16px;
        height: 16px;
        margin: auto;
        transition: fill 0.15s ease-in-out;
        fill: var(--m-btn);
        opacity: 0.6;
      }

      &:hover,
      &.focus-visible {
        outline: none;

        &::after {
          opacity: 0.4;
        }

        svg {
          fill: var(--m-btn-focus);
        }
      }
    }

    &--main {
      padding: 6px 24px;
    }

    &--main,
    &--second {
      border-radius: 100px;
      outline-offset: 3px;
    }

    &--ok {
      background-color: var(--m-accent);
      color: var(--text-white, #fff);

      &:hover,
      &.focus-visible {
        filter: saturate(1.5);
      }
    }

    &--second {
      padding: 6px 16px;
      --carcass: var(--m-accent);
      --body: var(--text-white, #fff);

      color: var(--carcass);
      border-color: var(--carcass);
      background-color: var(--body);

      &:hover,
      &.focus-visible {
        color: var(--body);
        background-color: var(--carcass);
      }
    }
  }

  @media (max-width: 480px) {
    &-overlay {
      padding: 8px 2px;
    }

    &__body {
      padding: 8px 4px 32px;
    }
  }
}

.msg-modal {
  min-height: 128px;
  overflow-wrap: break-word;
  $that: &;

  &__body {
    text-align: center;
    align-items: center;
  }

  &__icon {
    width: 72px;
    height: 72px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;

    &.icon-success {
      background-image: url(img/icons/check-round-green.svg);
    }

    &.icon-warning {
      background-image: url(img/icons/warning.svg);
    }

    &.icon-warning-check {
      background-image: url(img/icons/check-round-orange.svg);
    }

    &.icon-error {
      background-image: url(img/icons/exclamation-round-red.svg);
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    margin-top: 12px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
  }

  &__msg {
    margin-top: 12px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
  }

  &__details {
    margin-top: 12px;
    text-align: center;
    font-weight: 300;
    font-size: 13px;
  }

  &__msg,
  &__details,
  &__mess {
    max-width: 100%;

    &:empty {
      display: none;
    }

    a {
      color: var(--blue);
      text-decoration: underline;

      &:hover,
      &.focus-visible {
        text-decoration: none;
      }
    }
  }

  &__btn-wrap {
    margin-top: 12px;
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;

    &--row {
      flex-direction: row;
    }

    &--column {
      flex-direction: column;
    }

    button {
      min-width: 80px;
    }
  }
}

.common-modal {
  padding: 32px;
  max-width: 1170px;
  font-size: 20px;
  font-weight: 300;
  color: var(--text-dark);

  .c-modal__body {
    padding: 0;
  }

  .c-modal__btn--close-top {
    width: 32px;
    height: 32px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  h2 {
    margin-bottom: 32px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 26px;
    color: var(--blue);
    text-align: center;
  }

  h3 {
    color: var(--blue);
    margin: 18px 0;
    font-size: 20px;
  }

  h4 {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
  }

  p {
    margin-bottom: 40px;
  }

  ul {
    margin-bottom: 40px;

    &.no-bullets {
      li {
        padding-left: 0;

        &:before {
          display: none;
        }
      }
    }
  }

  li {
    position: relative;
    padding-left: 20px;

    &:before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      top: 12px;
      left: 0;
      border-radius: 50%;
      background-color: var(--blue);
    }
  }

  a {
    color: var(--blue);
    text-decoration: underline;

    &:hover,
    &.focus-visible {
      text-decoration: none;
    }
  }

  @media (max-width: 768px) {
    padding: 32px 24px;
    font-size: 16px;

    h2 {
      font-size: 20px;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 16px;
    }
  }
}
