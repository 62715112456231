.section {
  padding: 24px;
  min-height: 100vh;
  display: flex;
}

.container {
  margin: auto;
  max-width: 768px;
  text-align: center;
}

.heading {
  font-size: 32px;
  font-weight: 400;
}

.btnWrap {
  box-sizing: border-box;
}

.btn {
  --btn-bg: var(--black);
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

@media (max-width: 480px) {
  .section {
    padding: 12px;
  }

  .heading {
    font-size: 24px;
  }
}
