@import "mixins";

:root {
  --white: #fff;

  --black: #000;
  --black-2: #333;
  --black-3: #1c1c1c;

  --slate: #354052;
  --slate-2: #252f3e;
  --slate-3: #2d3646;

  --grey-normal: #ced4da;
  --grey-normal-2: #b5b5b5;

  --grey-light: #fafbfc;
  --grey-light-2: #f5f5f5;
  --grey-light-3: #ededed;
  --grey-light-4: #f8f8f8;

  --grey-dark: #747474;
  --grey-dark-2: #595959;
  --grey-dark-3: #8f8f8f;

  --grey-misc: #576a75;

  --red: #e2291c;
  --red-2: #a80b00;

  --green: #009731;
  --green-2: #42c461;
  --green-3: #108213;

  --orange: #ff9800;
  --orange-2: #ff6600;

  --blue: #00719c;
  --blue-2: #009bde;
  --blue-3: #00b9ff;
  --blue-4: #004873;
  --blue-5: #3b84f8;

  --yellow: #fff100;

  --pink: #ff0080;
  --blue-light: #00b6f6;

  --text-white: var(--white);
  --text-secondary: var(--grey-dark);
  --text-dark: var(--black-3);
  --text-main: var(--black);

  --bg-white: var(--white);
  --bg-grey: var(--grey-light-2);
  --bg-grey-2: var(--grey-light-3);

  --error: var(--red);

  --entry-accent: var(--blue-3);
  --entry-accent-2: #5eeba7;
  --entry-accent-ic: var(--pink);

  --scroll-width: 8px;
  --scroll: #a0a0a5;
  --scroll-hover: #7c7c83;

  --shadow: rgb(183 183 183 / 18%);
  --shadow-2: rgba(0, 0, 0, 0.14);
}

//@media (prefers-color-scheme: dark) {
//  html {
//    color-scheme: dark;
//  }
//
//  :root {
//    --text-secondary: var(--white);
//    --text-dark: var(--white);
//    --text-main: var(--white);
//
//    --bg-white: var(--black-2);
//    --bg-grey: var(--black-2);
//    --bg-grey-2: var(--black-3);
//
//    --shadow: rgb(0 0 0 / 18%);
//  }
//}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::after,
:before {
  box-sizing: border-box;
}


//add custom scrollbar for Firefox
@supports (scrollbar-width: thin) {
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--scroll) transparent;
  }
}

::-webkit-scrollbar {
  width: var(--scroll-width);
  height: var(--scroll-width);

  &-track {
    background-color: transparent;
  }

  &-thumb {
    background-color: var(--scroll);
    border-radius: 10px;
    min-height: 30px;

    &:hover {
      background-color: var(--scroll-hover);
    }
  }

  &-corner {
    display: none;
  }
}

//using polyfill for focus-visible (thanks to Safari): Start
:focus:not(.focus-visible) {
  outline: none;
}

:focus:not(:focus-visible) {
  outline: none;
}
//using polyfill for focus-visible: End

.hidden,
[hidden] {
  display: none !important;
}

.visible {
  display: var(--display, block) !important;
}

a,
button,
input,
label {
  font: inherit;
}

a,
button,
label {
  color: inherit;
}

a {
  text-decoration: none;
}

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &:disabled::placeholder {
    opacity: 0.3;
  }
}

button {
  border: none;
  cursor: pointer;
  background-color: transparent;
}

li {
  list-style-type: none;
}

img {
  max-width: 100%;
}

html.no-overflow,
body.no-overflow {
  overflow: hidden;
}

.main-body {
  color: var(--text-dark);
  font-family: Roboto, sans-serif, Arial, Helvetica;
  line-height: 1.5;
  font-weight: 400;

  &::-webkit-scrollbar-track {
    margin-top: -3px;
    margin-bottom: -3px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
}

a {
  cursor: pointer;

  &.focus-visible {
    outline-color: var(--accent, var(--blue));
    outline-style: solid;
    outline-offset: 1px;
  }
}

.asterisk {
  color: var(--orange);
  margin-right: 7px;
}

b {
  font-weight: 600;
}

em {
  font-style: italic;
}

.text-accent {
  color: var(--accent);
}

.main {
  flex-grow: 1;
  background-color: var(--bg-grey);
  display: flex; // this is added to allow the descendant of `.main` easily occupy all available height
  flex-direction: column
}

.container {
  width: 100%;
  max-width: 1562px;
  padding: 0 16px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0 4px;
  }
}

.b-section {
  padding: 32px 0;
  font-size: 14px;

  &__top {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 28px;
  }

  &__heading {
    margin: 0;
    font-size: 24px;
    line-height: 1.25;
    font-weight: 400;
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: scaleY(0);
  }

  to {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes slideUp {
  from {
    opacity: 1;
    transform: scaleY(1);
  }

  to {
    opacity: 0;
    transform: scaleY(0);
  }
}

@keyframes slideRight {
  from {
    opacity: 0;
    transform: translateX(-80%) scaleX(0.5);
  }

  to {
    opacity: 1;
    transform: translateX(0) scaleX(1);
  }
}

@keyframes slideLeft {
  from {
    opacity: 1;
    transform: translateX(0) scaleX(1);
  }

  to {
    opacity: 0;
    transform: translateX(-80%) scaleX(0.5);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
