button {
  &.asc {
    .path-arrow-up,
    .sort_svg__path-arrow-up {
      fill: black;
    }
  }

  &.des {
    .path-arrow-down,
    .sort_svg__path-arrow-down {
      fill: black;
    }
  }
}

.c-tabs-btns {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 2px;
  background-color: var(--grey-light-3);

  &__btn {
    padding: 6px 16px;
    border-radius: inherit;
    box-shadow: none;
    --btn-color: var(--grey-dark);
    --btn-bg: transparent;
    --btn-highlight-opacity: 1;

    span {
      position: absolute;
    }

    &:not(:last-of-type)::after {
      content: "";
      position: absolute;
      top: 8px;
      bottom: 8px;
      right: 0;
      color: inherit;
      background-color: currentColor;
      width: 1px;
      z-index: -1;
    }

    &::before {
      inset: 0 -2px;
      filter: none;
      box-shadow: inset 0 0 0 1px var(--grey-dark);
    }

    &.active,
    &:active,
    &.focus-visible {
      --btn-color: var(--text-main);
      --btn-bg: #fff;

      &::after {
        opacity: 0;
      }

      span {
        background-color: var(--btn-bg);
        display: block;
        inset: 0 -1px;
        z-index: -2;
        border-radius: inherit;
      }
    }

    &:active,
    &.focus-visible {
      z-index: 2;
    }
  }

  @include phone-medium {
    &__btn {
      padding-inline: 6px;
    }
  }
}

.c-dropdown {
  position: relative;

  &__head {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-btn {
      display: none;
    }
  }

  &__body {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 100%);
    isolation: isolate;

    & > * {
      display: none;
      transform-origin: top;

      &.visible {
        display: block;
      }
    }

    &::after {
      content: "";
      position: absolute;
      display: block;
      z-index: -1;
      inset: -14px;
    }
  }

  @media (min-width: 769px) {
    &:hover &__body > *,
    &.active &__body > * {
      display: block;
      animation: slideDown 0.2s ease-in-out;
    }

    &__body > * {
      animation: slideUp 0.2s ease-in-out;
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    &__head {
      &-btn {
        display: block;
        flex-grow: 1;
        position: relative;
        height: 100%;
        width: 50%;
        padding: 0.5em;

        &::after {
          content: "";
          position: absolute;
          display: block;
          top: 0;
          bottom: 0;
          right: 2px;
          width: 0.6em;
          height: 0.6em;
          margin: auto;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          background-image: url(img/icons/chevron.svg);
          opacity: 0.6;
        }
      }
    }

    &__body {
      position: static;
      width: 100%;
      transform: translate(0, 0);
      overflow: hidden;
      text-overflow: ellipsis;

      & > * {
        display: none;
      }
    }

    $that: &;

    &.active {
      #{$that}__head-btn::after {
        transform: rotate(180deg);
      }

      #{$that}__body > * {
        transform: translateY(6px);
        display: block;
      }
    }
  }
}

.c-burger {
  display: block;
  flex-shrink: 0;
  width: 32px;
  height: 20px;
  position: relative;
  background-color: transparent;
  transform-origin: center;
  transition: transform 0.3s ease-in-out;
  // overflow: hidden;

  span {
    display: block;
    position: absolute;
    left: 50%;
    width: 100%;
    height: 2px;
    background-color: var(--text-main);
    border-radius: 2px;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
    transform-origin: left;
    transform: translate(-50%, -50%);

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 50%;
    }

    &:nth-child(3) {
      top: 100%;
    }
  }

  &:hover span,
  &.focus-visible span {
    &:nth-child(2) {
      transform: translate(-50%, -50%) scaleX(0.8);
    }

    &:nth-child(3) {
      transform: translate(-50%, -50%) scaleX(0.6);
    }
  }

  &.active span,
  &:active span {
    width: calc(20px * 1.41);

    &:nth-child(1) {
      transform: translate(-50%, -50%) rotate(calc(3.14rad / 4));
    }

    &:nth-child(2) {
      transform: translate(-50%, -50%) scaleX(0);
    }

    &:nth-child(3) {
      transform: translate(-50%, -50%) rotate(calc(-1 * 3.14rad / 4));
    }
  }
}

.c-unsupported {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 350px;
  z-index: 1000;
  padding-top: 100px;
  background-color: #222;
  color: #fff;
  overflow: auto;

  &__text-wrap {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  &__title {
    font-size: 3rem;
    text-align: center;
  }

  &__item {
    margin-bottom: 10px;
  }

  &__text {
    margin-bottom: 16px;
  }

  &__link {
    text-decoration: underline;
    color: #0067b8;
  }
}

.c-service-svg {
  &--sm {
    flex-shrink: 0;
  }
}

.c-count {
  // make it round for one-digit counter and square for larger counters, 1px on top to make
  // number appear centered on 100% zoom
  padding: 1px 3px 0;
  background-color: var(--blue-2);
  color: var(--text-white);
  display: inline-block;
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  border-radius: 10px;
  vertical-align: middle;

  &--green {
    background-color: var(--green);
  }

  &--orange {
    background-color: var(--orange-2);
  }

  &--red {
    background-color: var(--red-2);
  }

  &--absolute {
    position: absolute;
    top: -6px;
    right: -6px;
  }
}

// styling tippy: start
[data-tippy-root] {
  z-index: 10000000 !important;
}

// allows to use --tippy-border-color variable to change border-color of tippy, note that tippy's theme must be data-theme="light-border"
.tippy-box[data-theme~="light-border"] {
  border-color: var(--tippy-border-color, rgba(0, 8, 16, 0.15));

  &[data-placement^="top"] > .tippy-arrow:after {
    border-top-color: var(--tippy-border-color, rgba(0, 8, 16, 0.2));
  }

  &[data-placement^="bottom"] > .tippy-arrow:after {
    border-bottom-color: var(--tippy-border-color, rgba(0, 8, 16, 0.2));
  }

  &[data-placement^="left"] > .tippy-arrow:after {
    border-left-color: var(--tippy-border-color, rgba(0, 8, 16, 0.2));
  }

  &[data-placement^="right"] > .tippy-arrow:after {
    border-right-color: var(--tippy-border-color, rgba(0, 8, 16, 0.2));
  }
}

.tippy-box[data-theme^="light"] {
  color: inherit;
  line-height: inherit;
}

.tippy-blue[data-theme] {
  padding: 16px;
  color: var(--text-dark);
  border-radius: 8px;
  background-color: var(--bg-white);
  font-weight: 300;
  text-align: center;
  --tippy-border-color: rgba(0, 147, 194, 0.2);

  .tippy-content {
    padding: 0;
  }
}

.tippy-common[data-theme] {
  padding: 16px;
  color: var(--text-dark);
  background-color: var(--bg-white);
  box-shadow: 0px 0px 6px rgba(183, 183, 183, 0.4);
  border-radius: 12px;

  .tippy-content {
    padding: 0;
  }
}

.tippy-menu[data-theme~="light"] {
  padding: 0;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 20%);
  border-radius: 6px;
  background-color: var(--bg-white);
  color: inherit;
  font-size: inherit;
  line-height: inherit;

  .tippy-content {
    padding: 0;
  }
}
// styling tippy: end

// styling simplebar: start
// simplebar styles are lazy-loaded after index.scss, so we have to increase specificity to override them
body {
  .simplebar-content-wrapper {
    outline: none;
  }

  .simplebar-scrollbar:before {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .simplebar-track.simplebar-horizontal {
    height: 8px;
  }

  .simplebar-track.simplebar-vertical {
    width: 8px;
  }
}
// styling simplebar: end

// styling react-select: start
// we pass `classNamePrefix: "react-select"` prop to easily style it
// increase specificity to easily override initial styles
body {
  .react-select {
    &__control {
      border-color: var(--grey-normal-2);
      line-height: 1;
      box-shadow: none;
      cursor: pointer;

      &--is-focused,
      &--is-focused:hover {
        border-color: var(--blue);
      }

      &--is-disabled {
        cursor: auto;
      }
    }

    &__multi-value {
      background-color: #f5f5f5;
      align-items: center;
    }

    &__multi-value__label {
      color: inherit;
    }

    &__multi-value__remove {
      cursor: pointer;
      align-self: stretch;
    }

    &__clear-indicator {
      padding: 0;
      cursor: pointer;
    }

    &__indicator-separator {
      margin-left: 4px; // keep this if we decide to make the separator visible
      display: none;
    }

    &__dropdown-indicator {
      padding: 8px;
      cursor: pointer;
      color: #5f5f5f;
    }

    &__menu {
      z-index: 10;
      overflow: hidden;
    }
  }
}
// styling react-select: end

// класс для ссылок при при входе в ЛК (верификация)
.linkMessage {
  color: var(--blue);
  &:hover {
    text-decoration: underline;
  }
}
//