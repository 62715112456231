/*
button recipes:
<button className="c-btn">Button</button>
<button className="c-btn c-btn--blue">Button</button>
<button className="c-btn c-btn--white">Button</button>
<button className="c-btn c-btn--blank">Button</button>
<button className="c-btn c-btn--white-to-blue">Button</button>
<button className="c-btn c-btn--blue-to-light">Button</button>
<button className="c-btn c-btn--light-blue">Button</button>
<button className="c-btn c-btn--red">Button</button>
<button className="c-btn c-btn--dark-red">Button</button>
<button className="c-btn c-btn--green">Button</button>
<button className="c-btn c-btn--icon c-btn--doc">Button</button>
<button className="c-btn c-btn--icon c-btn--doc c-btn--white">Button</button>
<button className="c-btn c-btn--icon c-btn--edit">Button</button>
<button className="c-btn c-btn--icon c-btn--dialog">Button</button>
<button className="c-btn c-btn--icon c-btn--download">Button</button>
<button className="c-btn c-btn--icon c-btn--download c-btn--white">Button</button>
<button className="c-btn c-btn--wired">Button</button>
<button className="c-btn c-btn--wired c-btn--blue-to-light">Button</button>
<button className="c-btn c-btn--wired c-btn--light-blue">Button</button>
<button className="c-btn c-btn--wired c-btn--red">Button</button>
<button className="c-btn c-btn--wired c-btn--dark-red">Button</button>
<button className="c-btn c-btn--wired c-btn--green">Button</button>
<button className="c-btn c-btn--chevron"></button>
<button className="c-btn c-btn--close"></button>

can't get necessary styles using provided variables? You're likely need something like this:
<button className="c-btn c-btn--blank my-btn">My btn</button>
.my-btn {
  padding: 8px 24px;
  --btn-bg: #5c5ed9;
  --btn-color: white;
  --btn-highlight-opacity: 0.6;

  &:before {
    box-shadow: 0 0 0 4px #5c5ed9;
  }

  &:disabled {
    filter: grayscale(0) !important;
    background-color: #00719c;
  }
}
*/

.c-btn {
  padding: 8px 16px;
  border-radius: 6px;
  flex-shrink: 0;
  display: inline-block;
  position: relative;
  isolation: isolate;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.14);
  cursor: pointer;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  transition:
    opacity 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    filter 0.15s ease-in-out;
  color: var(--b-color);
  background-color: var(--b-bg);
  border: 1px none var(--b-border);
  $that: &;
  //vars: start
  --b-color: var(--btn-color, #fff);
  --b-bg: var(--btn-bg, var(--accent, var(--blue)));
  --b-border: var(--btn-border, var(--b-color));
  --b-highlight: var(--btn-highlight, var(--blue-2, #00b9ff));
  --b-highlight-opacity: var(--btn-highlight-opacity, 0.3);
  --b-highlight-width: var(--btn-highlight-width, 3px);
  //vars: end
  /*
    public variables to customize the styles of c-btn:
    --btn-color --btn-bg --btn-bg-active --btn-border
    --btn-highlight --btn-highlight-opacity --btn-highlight-width
  */

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    background-color: transparent;
    border-radius: inherit;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    box-shadow: 0 0 0 var(--b-highlight-width) var(--b-bg);
    filter: saturate(2.5);
  }

  &:hover {
    text-decoration: none;
  }

  &.focus-visible,
  &:active {
    outline: none;

    &:before {
      opacity: var(--b-highlight-opacity);
    }
  }

  &.focus-visible:not(:active):not(&--blank):before {
    opacity: calc(var(--b-highlight-opacity) * 2);
  }

  @media (hover: none) {
    // use this only for touchscreens
    &:active {
      --b-bg: var(--btn-bg-active, var(--btn-bg, var(--accent, var(--blue))));
    }
  }

  &:disabled,
  &.disabled {
    filter: grayscale(1) !important;
    opacity: 0.7;
    cursor: auto;

    &.focus-visible,
    &:active {
      &:before {
        opacity: 0 !important;
      }
    }
  }

  &--highlight-default:before {
    filter: none;
    box-shadow: 0 0 0 3px var(--b-highlight);
  }

  &--wired {
    --btn-color: var(--b-bg);
    --btn-border: var(--b-bg);
    background-color: transparent;
    border-style: solid;

    &:before {
      inset: -1px;
    }

    &:hover:not(:disabled):not(.disabled),
    &.focus-visible:not(:disabled):not(.disabled) {
      background-color: var(--b-bg);
      --btn-color: #fff;
    }
  }

  &--blue {
    --btn-bg-active: var(--blue-4);
  }

  &--white {
    --btn-color: var(--blue);
    --btn-bg: #fff;

    &:before {
      filter: none;
      box-shadow: 0 0 0 var(--b-highlight-width) var(--b-highlight);
    }
  }

  &--blank {
    --b-color: var(--btn-color, var(--blue));
    --btn-bg: transparent;
    box-shadow: none;
    padding: 0;

    &:before {
      filter: none;
      box-shadow: 0 0 0 var(--b-highlight-width) var(--b-highlight);
    }
  }

  &--white-to-blue {
    --btn-color: var(--blue);
    --btn-bg: #fff;

    &:hover,
    &.focus-visible {
      --btn-color: #fff;
      --btn-bg: var(--blue);
    }
  }

  &--blue-to-light {
    &:hover,
    &.focus-visible {
      --btn-bg: var(--blue-3);
    }
  }

  &--light-blue {
    --btn-bg: var(--blue-3);
  }

  &--red {
    --btn-bg: var(--red);
  }

  &--dark-red {
    --btn-bg: var(--red-2);
  }

  &--green {
    --btn-bg: var(--green);
  }

  &--icon {
    padding-left: 32px;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 8px;
      width: 16px;
      height: 16px;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
  }

  &--doc {
    &::after {
      background-image: url(img/icons/doc-white.svg);
    }

    &#{$that}--white {
      &::after {
        background-image: url(img/icons/doc.svg);
      }
    }
  }

  &--edit::after {
    background-image: url(img/icons/pen-white.svg);
  }

  &--dialog::after {
    background-image: url(img/icons/dialog-bubble.svg);
  }

  &--download {
    &::after {
      background-image: url(img/icons/download-white.svg);
    }

    &#{$that}--white {
      &::after {
        background-image: url(img/icons/download.svg);
      }
    }
  }

  &--chevron {
    width: 24px;
    height: 24px;
    padding: 0;
    --btn-color: var(--blue);
    --btn-bg: transparent;
    box-shadow: none;

    &:before {
      filter: none;
      box-shadow: 0 0 0 var(--b-highlight-width) var(--b-highlight);
    }

    &::after {
      content: "";
      position: absolute;
      margin: auto;
      inset: 0;
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      background-image: url(img/icons/chevron.svg);
    }

    &.active {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &--close {
    padding: 0;
    width: 24px;
    height: 24px;
    display: block;
    border-radius: 8px;
    background-color: transparent;
    box-shadow: none;
    border: 1px solid transparent;

    &::after {
      content: "";
      position: absolute;
      margin: auto;
      inset: 0;
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      background-image: url(img/icons/cross-grey.svg);
    }

    &:hover {
      border-color: rgb(116 116 116 / 50%);
    }

    &:active:before {
      display: none;
    }
  }
}

.c-link {
  padding: 2px;
  display: inline-block;
  text-decoration: none;
  outline: none;
  position: relative;
  isolation: isolate;
  line-height: 1;
  background-color: var(--l-bg);
  border-radius: 6px;
  transition:
    opacity 0.2s ease-in-out,
    color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  $that: &;
  // vars: start
  --l-color: var(--link-color, var(--accent, var(--blue)));
  --l-bg: var(--link-bg, transparent);
  --l-highlight: var(--link-highlight, var(--l-color));
  --l-highlight-width: var(--link-highlight-width, 2px);
  //vars: end

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    background-color: transparent;
    border-radius: inherit;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    box-shadow: 0 0 0 var(--l-highlight-width) var(--l-highlight);
    filter: saturate(2.5);
  }

  &.focus-visible,
  &:active {
    outline: none;
  }

  &.focus-visible:not(:active):before {
    opacity: 1;
  }

  &--default {
    color: var(--l-color);

    &:hover,
    &.focus-visible {
      text-decoration: underline;
    }
  }

  &--opaque {
    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }

  &--download {
    width: 24px;
    height: 24px;
    margin-right: 0.4rem;

    &:after {
      content: "";
      position: absolute;
      inset: 0;
      margin: auto;
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      background-image: url(img/icons/download.svg);
    }
  }
}

.btn-google {
  padding: 10px 24px 10px 50px;
  position: relative;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.64);
  background-color: var(--bg-white);
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.14);
  transition:
    opacity 0.15s ease-in-out,
    background-color 0.15s ease-in-out;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 38px;
    height: 38px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 18px;
    background-image: url(img/icons/google/google-light-normal.svg);
    transition: background 0.15s ease-in-out;
    border-radius: inherit;
  }

  &.focus-visible {
    outline: 3px solid #4285f44d;
  }

  &:active {
    background-color: #eeeeee;
  }

  &:disabled {
    background-color: #eeeeee;
    color: rgba(0, 0, 0, 0.64);
    cursor: auto;

    &:after {
      background-image: url(img/icons/google/google-light-disabled.svg);
    }
  }

  &--blue {
    background-color: #4285f4;
    color: var(--white);

    &:after {
      background-color: var(--bg-white);
    }

    &:active {
      background-color: #3367d6;
    }

    &:disabled {
      &:after {
        background-color: transparent;
      }
    }
  }
}
