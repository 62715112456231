.form-field {
  $that: &;

  //TODO deal with this
  &--row {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px 16px;

    &.mb0 {
      margin-bottom: 0;
    }
  }

  &.error {
    #{$that}__field,
    .ck.ck-editor__main > .ck-editor__editable {
      border-color: var(--error);
    }

    #{$that}__phone-field .PhoneInputCustom__input {
      border-color: var(--error);
    }

    #{$that}__select-field .react-select {
      &__control {
        border-color: var(--error);

        &--is-focused,
        &--is-focused:hover {
          border-color: var(--error);
        }
      }
    }
  }

  &.grow,
  &__wrap.grow {
    flex-grow: 1;

    &-2 {
      flex-grow: 3;
    }

    &-3 {
      flex-grow: 3;
    }
  }

  &.fb,
  &__wrap.fb {
    &-180 {
      flex-basis: 180px;
    }

    &-240 {
      flex-basis: 240px;
    }
  }

  &__label {
    color: var(--text-secondary);
    flex-grow: 1;
    flex-basis: 120px;
    display: inline-block;

    &.above {
      margin-bottom: 8px;
    }
  }

  &__wrap {
    position: relative;
    flex-grow: 3;
    flex-basis: 240px;

    &.miw-184 {
      min-width: 184px;
    }
  }

  &__wrap-inner {
    position: relative;
  }

  &__input-field,
  &__textarea-field,
  &__datepicker-field {
    font-size: inherit;
    line-height: 14px;
    padding: 8px;
    width: 100%;
    color: var(--text-main);
    background-color: #fff;
    border: 1px solid var(--bd-grey-light, var(--grey-normal-2));
    border-radius: 6px;
    outline: none;
    display: block;

    &::placeholder {
      color: var(--text-secondary);
    }

    &:focus {
      border-color: var(--focus);
    }

    &[readonly],
    &[disabled] {
      background-color: hsl(0, 0%, 97%);
    }
  }

  &__input-field,
  &__datepicker-field {
    height: 32px;
  }

  &__textarea-field {
    resize: vertical;
    min-height: 32px;
    height: 48px;

    &--short {
      height: 32px;
    }
  }

  &__select-field {
    .react-select__control {
      border: 1px solid var(--bd-grey-light, var(--grey-normal-2));

      &--is-focused {
        border-color: var(--focus, var(--blue));
      }
    }
  }

  &__phone-field.PhoneInputCustom {
    color: var(--text-main);
    border: 1px solid var(--bd-grey-light, var(--grey-normal-2));
    border-radius: 6px;

    &.PhoneInput--focus {
      border-color: var(--focus);
    }

    .PhoneInputCustom__input {
      line-height: 14px;
      padding: 8px;

      &::placeholder {
        color: var(--text-secondary);
      }
    }
  }

  &__error {
    display: inline-block;
    color: var(--error);
  }
}

.c-form {
  font-size: inherit;
  --text-secondary: #8a979f;
  --text-main: #1c1c1c;
  --bd-grey-light: var(--grey-normal-2);
  --focus: var(--blue);
  --error: var(--red);
  $that: &;

  &__row {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px 16px;
  }

  &__fields {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px 32px;
  }

  &__btn-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
  }

  .align-bottom {
    align-items: flex-end;
  }
}
