@import "mixins";

.c-table {
  font-family: inherit;
  font-weight: 400;
  font-size: 12px;
  width: 100%;
  border-collapse: collapse;
  --t-bg-second: var(--table-bg-second, var(--grey-light-2));
  --t-td-padding-fallback: 16px 8px; //fallback values moved to separate variables to stop freaking php-storm complaining about actually correct writing "var(--some-var, 12px 24px)"
  --t-th-padding-fallback: 8px 8px;
  --t-td-padding: var(--table-td-padding, var(--table-cell-padding, var(--t-td-padding-fallback)));
  --t-th-padding: var(--table-th-padding, var(--table-cell-padding, var(--t-th-padding-fallback)));
  --t-row-gap: var(--table-row-gap, 16px);
  --t-row-bg: var(--table-row-bg, var(--grey-light-4));

  &--two-bg,
  &--two-bg-odd {
    tbody {
      tr:nth-child(odd) td {
        background-color: var(--t-bg-second);
      }
    }
  }

  &--two-bg-even {
    tbody {
      tr:nth-child(even) td {
        background-color: var(--t-bg-second);
      }
    }
  }

  &--separate {
    margin-top: calc(-1 * var(--t-row-gap)); // compensate for row-gap
    border-collapse: separate;
    border-spacing: 0 var(--t-row-gap);

    tbody {
      tr {
        filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.14));

        td {
          background-color: var(--t-row-bg);

          &:first-child {
            border-radius: 8px 0 0 8px;
          }

          &:last-child {
            border-radius: 0 8px 8px 0;
          }
        }
      }
    }
  }

  &__head,
  thead {
    color: #8a979f;
  }

  &__th,
  th {
    padding: var(--t-th-padding);
    font-weight: inherit;
    text-align: left;
  }

  &__td,
  td {
    padding: var(--t-td-padding);
  }

  &__th,
  th,
  &__td,
  td {
    &.text-centered,
    &.text-center {
      text-align: center;
    }

    &.text-left {
      text-align: left;
    }

    &.text-right {
      text-align: right;
    }
  }

  &__sort-btn {
    position: relative;
    background-color: transparent;
    line-height: 1;
    border: none;
    padding: 0 18px 0 0;
    color: inherit;
    transition:
      opacity 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    outline: none;
    text-align: left;
    border-radius: 2px;

    &.focus-visible {
      outline: none;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }

  &__sort-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.grid-table {
  padding: 24px;
  background-color: #fff;
  box-shadow: 0px 0px 6px rgba(183, 183, 183, 0.4);
  border-radius: 12px;
  font-size: 14px;
  $that: &;

  & > div:last-child {
    margin-bottom: 0;
  }

  &--short-rows {
    #{$that}__row > * {
      max-height: 100px;
    }
  }

  &__row {
    padding: 12px 16px;
    margin-bottom: 16px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    background-color: var(--grey-light-2);
    display: grid;
    gap: 8px;
    align-items: center;

    & > * {
      min-width: 0;
    }

    &--head {
      background-color: transparent;
      box-shadow: none;
      color: #8a979f;
    }
  }

  &__sort-btn {
    padding: 2px 22px 2px 2px;
    box-shadow: none;
    text-align: left;

    &:active {
      &::before {
        display: none;
      }
    }
  }

  &__sort-icon {
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__text-cell {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include tablet-landscape {
    padding-inline: 16px;

    &__row {
      padding-inline: 8px;
      gap: 24px 4px;
    }
  }
}
