@import "mixins";

//to avoid using !important we increase specificity by placing utility-classes inside body,
//to override its values (e.g. on media-queries) use ".your_class.utility { prop: new_value }"
body {
  .fw {
    &-300 {
      font-weight: 300;
    }

    &-400 {
      font-weight: 400;
    }

    &-500 {
      font-weight: 500;
    }

    &-600 {
      font-weight: 600;
    }

    &-700 {
      font-weight: 700;
    }
  }

  @include pixel-increment(".m", margin);
  @include pixel-increment(".mx", (margin-right, margin-left));
  @include pixel-increment(".my", (margin-top, margin-bottom));
  @include pixel-increment(".mt", margin-top);
  @include pixel-increment(".mb", margin-bottom);
  @include pixel-increment(".ml", margin-left);
  @include pixel-increment(".mr", margin-right);

  //add additional classes for 2, 6, 10 px
  @include pixel-increment(".m", margin, 2, 10);
  @include pixel-increment(".mx", (margin-right, margin-left), 2, 10);
  @include pixel-increment(".my", (margin-top, margin-bottom), 2, 10);
  @include pixel-increment(".mt", margin-top, 2, 10);
  @include pixel-increment(".mb", margin-bottom, 2, 10);
  @include pixel-increment(".ml", margin-left, 2, 10);
  @include pixel-increment(".mr", margin-right, 2, 10);

  .mx-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mr-auto {
    margin-right: auto;
  }

  .ml-auto {
    margin-left: auto;
  }

  .mt-auto {
    margin-top: auto;
  }

  .mb-auto {
    margin-bottom: auto;
  }

  @include pixel-increment(".p", padding);
  @include pixel-increment(".px", (padding-right, padding-left));
  @include pixel-increment(".py", (padding-top, padding-bottom));
  @include pixel-increment(".pt", padding-top);
  @include pixel-increment(".pb", padding-bottom);
  @include pixel-increment(".pl", padding-left);
  @include pixel-increment(".pr", padding-right);

  //add additional classes for 2, 6, 10 px
  @include pixel-increment(".p", padding, 2, 10);
  @include pixel-increment(".px", (padding-right, padding-left), 2, 10);
  @include pixel-increment(".py", (padding-top, padding-bottom), 2, 10);
  @include pixel-increment(".pt", padding-top, 2, 10);
  @include pixel-increment(".pb", padding-bottom, 2, 10);
  @include pixel-increment(".pl", padding-left, 2, 10);
  @include pixel-increment(".pr", padding-right, 2, 10);

  .maw {
    &-none {
      max-width: unset;
    }

    &-100 {
      max-width: 100px;
    }

    &-150 {
      max-width: 150px;
    }

    &-200 {
      max-width: 200px;
    }

    &-250 {
      max-width: 250px;
    }

    &-300 {
      max-width: 300px;
    }

    &-400 {
      max-width: 400px;
    }

    &-500 {
      max-width: 500px;
    }
  }

  .miw-none {
    min-width: unset;
  }

  .flex {
    display: flex;
  }

  .block {
    display: block;
  }

  .inline-block {
    display: inline-block;
  }

  .flex-grow {
    flex-grow: 1;

    &-0 {
      flex-grow: 0;
    }

    &-1 {
      flex-grow: 1;
    }
  }

  .flex-shrink {
    flex-shrink: 1;

    &-0 {
      flex-shrink: 0;
    }

    &-1 {
      flex-shrink: 1;
    }
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-align {
    &-center {
      align-items: center;
    }

    &-start {
      align-items: flex-start;
    }

    &-end {
      align-items: flex-end;
    }

    &-stretch {
      align-items: stretch;
    }
  }

  .flex-justify {
    &-center {
      justify-content: center;
    }

    &-space-between,
    &-between {
      justify-content: space-between;
    }

    &-evenly,
    &-even {
      justify-content: space-evenly;
    }

    &-start {
      justify-content: flex-start;
    }

    &-end {
      justify-content: flex-end;
    }
  }

  .flex-aligned {
    display: flex;
    align-items: center;
  }

  .flex-centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gap {
    &-4 {
      gap: 4px;
    }

    &-8 {
      gap: 8px;
    }

    &-12 {
      gap: 12px;
    }

    &-16 {
      gap: 16px;
    }

    &-24 {
      gap: 24px;
    }

    &-28 {
      gap: 28px;
    }

    &-32 {
      gap: 32px;
    }
  }

  .text-center,
  .text-centered {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .opacity {
    &-0 {
      opacity: 0;
    }

    &-1 {
      opacity: 0.1;
    }

    &-2 {
      opacity: 0.2;
    }

    &-2 {
      opacity: 0.2;
    }

    &-3 {
      opacity: 0.3;
    }

    &-4 {
      opacity: 0.4;
    }

    &-5 {
      opacity: 0.5;
    }

    &-6 {
      opacity: 0.6;
    }

    &-7 {
      opacity: 0.7;
    }

    &-8 {
      opacity: 0.8;
    }

    &-9 {
      opacity: 0.9;
    }

    &-10 {
      opacity: 1;
    }
  }

  .w-100 {
    width: 100%;
  }
}
