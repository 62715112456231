.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  border-radius: inherit;
  opacity: 0;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--bg, #fff);
    opacity: 0.95;
    border-radius: inherit;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    max-width: 200px;
    max-height: 200px;
  }

  &-enter-active,
  &-appear-active {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }

  &-enter-done,
  &-appear-done {
    opacity: 1;
  }

  &-no-transition {
    opacity: 1;
    transition: none;
  }

  &-opaque:before {
    background-color: rgba(#fff, 0.9);
  }
}

body > .loader,
#root > .loader,
#loader-root > .loader,
.loader.fixed {
  position: fixed !important;
  z-index: 10000;
}
