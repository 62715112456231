.lang-switcher {
  color: var(--text-main);
  font-size: 14px;
  flex-shrink: 0;

  &__label {
    display: flex;
    align-items: flex-end;
    gap: 2px;
    line-height: 1;
  }

  .react-select__control {
    border: none !important;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.24);
  }
}
